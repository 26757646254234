<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="800px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Nueva Actividad
          </span>
        </v-card-title>
        <v-card-text class="mt-10">
          <validation-observer ref="form">
            <v-container >
              <div class="row">
                <div class="col-12 mb-0 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nombre Actividad"
                    vid="name"
                    rules="required|max:120"
                  >
                    <v-text-field
                      v-model="model.name"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Nombre Actividad"
                      required
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col-4 my-0 py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Fecha"
                    vid="date"
                    rules="required"
                  >
                    <date-picker ref="date" label="Fecha" v-model="model.date" :error="errors"/>
                  </validation-provider>
                </div>
                <div class="col-4 my-0 py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hora Inicial"
                    vid="initialHour"
                    rules="required"
                  >
                    <v-select
                      :items="times"
                      v-model="model.initialHour"
                      item-text="description"
                      item-value="time"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Hora Inicial"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-4 my-0 py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hora Final"
                    vid="finalHour"
                    rules="required"
                  >
                    <v-select
                      :items="times"
                      v-model="model.finalHour"
                      item-text="description"
                      item-value="time"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Hora Final"
                      required
                    />
                  </validation-provider>
                </div>
              </div>
              <div v-for="(item, index) in model.speakers" class="row" :key="index">
                <div class="col-5 my-0 py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nombre Conferencista"
                    vid="speakerName"
                  >
                    <v-text-field
                      v-model="item.name"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Nombre Conferencista"
                    />
                  </validation-provider>
                </div>
                <div class="col-5 my-0 py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Empresa Conferencista"
                    vid="speakerCompany"
                  >
                    <v-text-field
                      v-model="item.companyName"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Empresa Conferencista"
                    />
                  </validation-provider>
                </div>
                <div class="col-2 my-0 py-0" v-if="model.speakers.length > 1">
                  <v-btn
                    color="black"
                    class="text--primary text-none elevation-0"
                    @click="deleteDateRow(index)"
                  >
                    <v-icon color="white">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-0 pt-0">
                  <v-btn
                    color="secondary"
                    class="text-none elevation-0"
                    @click="addDateRow"
                    dark>
                    Agregar
                  </v-btn>
                </div>
              </div>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveAcademicActivity">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import dateTimeMixin from '@/mixins/dateTimeMixin'
import { ACADEMIC_ACTIVITIES_URL } from '@/constants/ServicesMobileConstants'

export default {
  name: 'AcademicActivityModal',
  props: {
    fairId: {
      type: Number
    },
    companies: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      datesCount: 1,
      isActive: false,
      model: {
        name: null,
        date: null,
        initialHour: null,
        finalHour: null,
        speakers: [
          {
            name: null,
            companyName: null
          }
        ]
      }
    }
  },
  methods: {
    addDateRow () {
      this.model.speakers.push(
        {
          name: null,
          companyName: null
        }
      )
    },
    deleteDateRow (index) {
      this.model.speakers.splice(index, 1)
    },
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        name: null,
        date: null,
        initialHour: null,
        finalHour: null,
        speakers: [
          {
            name: null,
            companyName: null
          }
        ]
      }
    },
    open (generalActivity) {
      if (generalActivity) {
        this.model.id = generalActivity.id
        this.model.name = generalActivity.name
        this.model.date = generalActivity.date
        this.model.initialHour = generalActivity.initialHour
        this.model.finalHour = generalActivity.finalHour
        const speakers = []
        generalActivity.speakers.forEach((elem) => {
          speakers.push({
            id: elem.id,
            name: elem.name,
            companyName: elem.companyName
          })
        })
        this.model.speakers = speakers
      }
      this.isActive = true
    },
    saveAcademicActivity () {
      this.model.fairId = this.fairId
      this.$refs.form.validate().then((result) => {
        if (result) {
          if (this.model.id) {
            this.putMobile(ACADEMIC_ACTIVITIES_URL + '/' + this.model.id, this.model)
              .then((res) => {
                this.close()
                this.showSuccess(res.data.success)
                this.$emit('onClose')
              })
          } else {
            this.postMobile(ACADEMIC_ACTIVITIES_URL, this.model)
              .then((res) => {
                this.close()
                this.showSuccess(res.data.success)
                this.$emit('onClose')
              })
          }
        }
      })
    }
  },
  mixins: [
    crudServiceMobileMixin,
    notificationMixin,
    loaderMixin,
    dateTimeMixin
  ]
}
</script>
