var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fair)?_c('fair-layout',{attrs:{"fair-id":_vm.fair.id,"title":"Configuración Evento Académico"},scopedSlots:_vm._u([{key:"banner",fn:function(){return [(_vm.fair)?_c('banner',{attrs:{"title":_vm.fair.name,"type":"banner--admin","options":{padding: '30px'}}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"px-7"},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3 row"},[_c('div',{staticClass:"col py-0"},[_c('label',{staticClass:"label-chooser"},[_vm._v("Banner Interno")]),_c('file-chooser',{ref:"academicActivityFileChooser",attrs:{"options":{
              height: '110px',
              description: 'Haz click para cargar la imagen',
              regex: /\.(jpg|jpeg|png|gif)$/,
              error: 'Solo se permiten imagenes',
              fileSize: 2,
            }},on:{"input":_vm.onLoadAcademicActivityImageUrl}})],1),_c('v-tooltip',{staticClass:"col-auto",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.onDeleteImage}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,false,547374292)},[_c('span',[_vm._v("Eliminar Imagen")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-0 pb-0 d-flex align-center justify-space-between"},[_c('p',[_vm._v(" Los recursos de imágenes para las secciones deben cargarse en formato PNG o JPG, de dimensiones (1280px por 737px) y tamaño no superior a 100kbs. ")]),_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openAdminExpositorModal()}}},[_vm._v(" Nuevo ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"label":"Nombres Actividad","outlined":"","dense":"","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.params.filterName),callback:function ($$v) {_vm.$set(_vm.params, "filterName", $$v)},expression:"params.filterName"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-end"},[_c('v-btn',{staticClass:"white--text text-none elevation-0 mr-2",attrs:{"color":"primary"},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" fa-search ")]),_vm._v(" Buscar ")],1),_c('v-btn',{staticClass:"text-none elevation-0",attrs:{"color":"default"},on:{"click":_vm.clean}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" fa-broom ")]),_vm._v(" Limpiar ")],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{ref:"academicActivities",attrs:{"url":_vm.url,"params":_vm.params,"headers":_vm.headers,"mobile":true},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0",attrs:{"color":"accent","hide-details":"","dense":""},on:{"click":function($event){return _vm.onChangeStatusAcademicActivity(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openAdminExpositorModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Actividad")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onDeleteActivity(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Actividad")])])]}}],null,false,3048335416)})],1)]),_c('academic-activity-modal',{ref:"academicActivityModal",attrs:{"fairId":_vm.fair.id},on:{"onClose":_vm.onClose}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":("¿Deseas " + ((Boolean(_vm.selectedActivity.isActive) ? 'activar' : 'desactivar')) + "  la actividad " + (_vm.selectedActivity.name) + "?"),"is-active":_vm.isConfirmChangeStatusAcademicActive},on:{"onClose":function($event){return _vm.closeChangeStatusActivityModal()},"onConfirm":function($event){return _vm.changeStatusAcademicActivity()}}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":("¿Deseas eliminar la actividad " + (_vm.selectedActivity.name) + "?"),"is-active":_vm.isConfirmDeleteActivity},on:{"onClose":function($event){return _vm.closeDeleteActivityModal()},"onConfirm":function($event){return _vm.deleteAcademicActivity()}}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":"¿Deseas eliminar la imagen del evento académico","is-active":_vm.isConfirmDeleteImage},on:{"onClose":function($event){_vm.isConfirmDeleteImage = false},"onConfirm":function($event){return _vm.deleteImage()}}})],1)]},proxy:true}],null,false,3023704107)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }